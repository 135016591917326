import styled from "styled-components";

import { ReactComponent as GitHub } from '../../icons/github.svg';
import { ReactComponent as StackOverflow } from '../../icons/stack-overflow.svg';
import { ReactComponent as LinkedIn } from '../../icons/linkedin.svg';
import { ReactComponent as Instagram } from '../../icons/instagram.svg';
import { ReactComponent as Facebook } from '../../icons/facebook.svg';
import { ReactComponent as Twitter } from '../../icons/twitter.svg';
import { ReactComponent as Contrast } from '../../icons/contrast.svg';

const IconsComponents = {
	GitHub, StackOverflow, LinkedIn, Instagram, Facebook, Twitter, Contrast
}

function Icon({ type, title = "icon" }) {
	const Image = styled(IconsComponents[type])`
	width: var(--big-size);
		height: auto;

		fill: var(--b-color);
		:active {
			fill: var(--e-color);
		}
	`;

	return (
		<Image title={title} />
	);
}

export default Icon;