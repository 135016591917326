import styled from "styled-components";
import Line from "../shared/Line";

const List = styled.ul`
	list-style-type: none;
	margin-block-start: 0;
	padding-inline-start: 0;
`;

const Title = styled.h2`
	margin-left: 4pt;
`;

const CategoryLine = styled(Line)`
	position: relative;
	top: -10pt;
	width: 50%;
	max-width: 500px;
	min-width: 170px;
`;

function Category(props) {
	const ItemComponent = props['item-component'];
	return (
		<section>
			<Title>{props.title}</Title>
			<CategoryLine color='c' />
			<List>
				{props.data.map((item, index) =>
					(ItemComponent) ?
						<ItemComponent key={index} {...item} /> :
						<li key={index}>{JSON.stringify(item)}</li>
				)}
			</List>
		</section>
	);
}

export default Category;