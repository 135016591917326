import { Fragment, useEffect, useState } from 'react';

import Container from '../shared/Container';
import Header from '../header/Header';
import Category from '../category/Category';
import Job from '../category-item/Job';
import Skill from '../category-item/Skill';
import Language from '../category-item/Language';
import Footer from '../footer/Footer';

import CV from '../../cv';

const getDarkMedia = () => window.matchMedia('(prefers-color-scheme: dark)');
const getNewThemeColor = () => window.getComputedStyle(document.body, "").getPropertyValue("background-color");
const getModeName = (isDark) => isDark ? 'dark' : 'light';

function App() {
	const [mode, setMode] = useState(getModeName());

	const onModeChange = (mode) => {
		setMode(mode);

		document.body.className = mode;

		document.querySelector("meta[name='theme-color']").content = getNewThemeColor();
	}

	useEffect(() => {
		const eventListener = event => onModeChange(getModeName(event.matches));

		getDarkMedia().addEventListener('change', eventListener);

		onModeChange(getModeName(getDarkMedia().matches));

		return () => {
			getDarkMedia().removeEventListener('change', eventListener);
		}
	}, []);

	return (
		<Fragment>
			<Container>
				<Header name={CV.name} dataOfBirth={CV.dataOfBirth} image={CV.image} intro={CV.intro} />
				<Category title='Experience' data={CV.jobs} item-component={Job} />
				<Category title='Skills' data={CV.skiles} item-component={Skill} />
				<Category title='Languages' data={CV.languages} item-component={Language} />
			</Container>
			<Footer links={CV.links} modeToChange={getModeName(mode !== 'dark')} onModeChange={onModeChange} />
		</Fragment>
	);
}

export default App;
