import styled from "styled-components";

const Link = styled.a`
	:link,
	:visited,
	:focus,
	:hover {
		color: inherit;
	}

	:active {
		color: var(--e-color);
	}

	+ a {
		margin-left: 10pt;
	}
`;

export default Link;