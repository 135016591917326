import styled from "styled-components";
import Item from "./Item";

const JobItem = styled(Item)`
	width: 80%;
	min-width: 330px;

	> div + div {
		margin-top: 4pt;
	}
`;

const Date = styled.div`
	margin-left: 4pt;
	font-size: 0.6rem;
	color: var(--e-color);
`;

const Title = styled.div`
	font-weight: bold;
	margin-left: 8pt;
`;

const Description = styled.div`
	font-size: 0.8rem;
	margin-left: 8pt;
`;

function Job(props) {
	return (
		<JobItem>
			<Date>{props.start_date} - {(props.end_date) ? props.end_date : 'Today'}</Date>
			<Title>{props.title}{(props.company) ? ` at ${props.company}` : ''}</Title>
			<Description>{props.description}</Description>
		</JobItem>
	);
}

export default Job;