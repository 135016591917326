import styled from "styled-components";

import ProfileImage from "./ProfileImage";
import getByDateString from "../../helpers/Age";

const Wrapper = styled.header`
	display: var(--flex-or-block-display);
	margin-top: 10pt;

	> div {
		:first-child {
			display: flex;
			margin: 0 auto;
			align-items: center;
		}
		+ div {
			margin-left: var(--ten-pt-or-zero);
			text-align: var(--left-or-center);
			> h1 > small {
				font-size: 1rem;
			}
		}
	}
`;

function Header(props) {
	return (
		<Wrapper>
			<div>
				<ProfileImage src={props.image} />
			</div>
			<div>
				<h1>{`${props.name}`} <small>{`(${getByDateString(props.dataOfBirth)})`}</small></h1>
				<p>{props.intro}</p>
			</div>
		</Wrapper>
	);
}

export default Header;