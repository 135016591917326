import styled from "styled-components";

import Container from '../shared/Container';
import Icon from './Icon';
import ChangeMode from "./ChangeMode";
import Link from '../shared/Link';
import Line from '../shared/Line';

const Wrapper = styled.div`
	position: var(--fixed-or-static);
	bottom: 0;
	width: 100%;
`;

const StyledFooter = styled.footer`
	margin-top: 2pt;
	text-align: center;
	background-color: var(--d-color);
	width: 100%;
`;

const FooterContainer = styled(Container)`
	display: var(--flex-or-block-display);
	padding: 20pt 4pt;
`;

const Box = styled.div`
	width: var(--half-or-full);
`;

const LeftBox = styled(Box)`
	text-align: var(--left-or-center);
`;

const RightBox = styled(Box)`
	margin-top: var(--zero-or-twenty-pt);
	text-align: var(--right-or-center);
`;

function Footer(props) {
	return (
		<Wrapper>
			<Line color='c' />
			<StyledFooter>
				<FooterContainer>
					<LeftBox>
						{props.links.map((item, index) =>
							<Link key={index} href={item.link} target="_blank" rel="noopener noreferrer">
								<Icon type={item.type} title={item.type} />
							</Link>
						)}
					</LeftBox>
					<RightBox>
						<ChangeMode modeToChange={props.modeToChange} onModeChange={props.onModeChange} />
					</RightBox>
				</FooterContainer>
			</StyledFooter>
		</Wrapper>
	);
}

export default Footer;