import Link from "../shared/Link";
import Icon from "./Icon";

function ChangeMode(props) {
	return (
		<Link onClick={() => { props.onModeChange(props.modeToChange); }}>
			<Icon type="Contrast" title={props.modeToChange + ' mode'} />
		</Link>
	);
}

export default ChangeMode;