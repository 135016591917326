const CV = {
	name: 'Orel Hadad',
	image: 'https://www.gravatar.com/avatar/e549b6552b06f3a4c02f1bf8a4ef597f?s=2048&d=mp',
	dataOfBirth: '1989/12/17',
	intro: 'Software Expert with 10+ years of experience designing, developing and deploying software products. My greatest strength is self-taught, being a fast learner, having excellent human relations, with a high work ethic.',
	jobs: [
		{
			start_date: 'January 2022',
			end_date: '',
			title: 'Team Leader',
			company: 'Ongage',
			description: ''
		},
		{
			start_date: 'March 2018',
			end_date: 'May 2019',
			title: 'Full-Stack Developer',
			company: 'AcureRate',
			description: 'Developing referrals platform, discovering people and their relationships based on automated big-data research.'
		},
		{
			start_date: 'September 2016',
			end_date: 'February 2018',
			title: 'Back End Developer',
			company: 'MyCheck',
			description: 'Full ownership at five microservices as part of payment solution offered to businesses in the hospitality industry.'
		},
		{
			start_date: 'September 2015',
			end_date: 'April 2016',
			title: 'Full-Stack Developer',
			company: 'AlgoSeller',
			description: 'Maintenance and improve the In-House CRM system include payments, call center and landing pages.'
		},
		{
			start_date: 'November 2013',
			end_date: 'August 2015',
			title: 'Back End Developer',
			company: 'B7WIN',
			description: 'Developing a real-time sports betting platform.'
		},
		{
			start_date: 'September 2005',
			end_date: 'December 2013',
			title: 'Full-Stack Freelancer',
			company: null,
			description: 'I have participated in a wide range of projects in various fields.'
		}
	],
	skiles: [
		{ name: 'Software architecture' },
		{ name: 'Design patterns' },
		{ name: 'Object Oriented' },
		{ name: 'Test-driven development' },
		{ name: 'PHP' },
		{ name: 'Java' },
		{ name: 'NodeJS' },
		{ name: 'Python' },
		{ name: 'JavaScript' },
		{ name: 'HTML' },
		{ name: 'CSS' },
		{ name: 'SH' },
		{ name: 'MySQL' },
		{ name: 'MongoDB' },
		{ name: 'Neo4j' },
		{ name: 'Memcached' },
		{ name: 'Redis' },
		{ name: 'JSON' },
		{ name: 'XML' },
		{ name: 'AWS' },
		{ name: 'GCP' },
		{ name: 'Apache' },
		{ name: 'Nginx' },
		{ name: 'Docker' },
		{ name: 'Composer' },
		{ name: 'Maven' },
		{ name: 'Gradle' },
		{ name: 'GIT' }
	],
	languages: [
		{ name: 'Hebrew', level: 'Native' },
		{ name: 'English', level: 'Excellent command' }
	],
	links: [
		{ type: 'GitHub', link: 'https://github.com/g0dlight' },
		{ type: 'StackOverflow', link: 'https://stackoverflow.com/users/2718028/godlight' },
		{ type: 'LinkedIn', link: 'https://www.linkedin.com/in/orelhadad' },
		{ type: 'Instagram', link: 'https://www.instagram.com/orel.hadadik' },
		{ type: 'Facebook', link: 'https://www.facebook.com/g0dlight' },
		{ type: 'Twitter', link: 'https://twitter.com/g0dlight' }
	]
};

export default CV;